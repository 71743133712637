import { Heading, Icon, Link, Text } from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import { trim } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { RiShoppingBasket2Line } from "react-icons/ri"
import { usePage } from "~/hooks/usePage"
import { OrderEntity } from "~/interfaces/entities/Order"
import { replaceShortCodes } from "~/services/replaceShortCodes"

interface Props {
  appConfig: AppEntityHydrated
  placedOrder: OrderEntity
  title: string
  body: string
}

const OrderThankYouText: React.FC<Props> = ({
  appConfig,
  placedOrder,
  title,
  body,
}) => {
  const { t } = useTranslation()
  const defaultTitle = t("components.blocks.OrderThankYouBlock.Title")
  const defaultBody = t("components.blocks.OrderThankYouBlock.Body", {
    name: "[order field=firstName]",
    appDisplayName: "[order field=appDisplayName]",
    dayOrEvening: "[order field=dayOrEvening]",
  })

  const { page: orderFromPage } = usePage(placedOrder.orderFromPageId)
  const { data: order } = placedOrder

  const { pathname: basePathname } = new URL(appConfig.baseUrl)

  return (
    <>
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize={{ base: "2xl", md: "4xl" }}
        color="#3B4F69"
      >
        {replaceShortCodes(title || defaultTitle, { appConfig, order })}
      </Heading>

      <Text
        textAlign="center"
        fontSize={{ base: "normal", md: "lg" }}
        color="gray.500"
        margin="0 auto"
        maxWidth="2xl"
        mt={4}
        mb={4}
      >
        {replaceShortCodes(body || defaultBody, { appConfig, order })}
      </Text>

      <Link
        id="p-place-another-order"
        color="primary.500"
        href={`${basePathname}${trim(orderFromPage.path, "/")}` ?? "/"}
        textDecoration="underline"
      >
        <Icon as={RiShoppingBasket2Line} verticalAlign={-1.5} mr={0.5} />
        {t("components.blocks.OrderCompleteBlock.PlaceAnotherOrder")}
      </Link>
    </>
  )
}

export default OrderThankYouText
