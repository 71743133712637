import { useSelector } from "react-redux"
import { EntityId, PrintServiceProductPriceEntity } from "@jackfruit/common"
import { printServiceProductPricesSelector } from "~/redux/state/printServiceProductPrices"
import { RootState } from "~/redux/store"

export const useProductPrices = (ids: EntityId[]) => {
  const productPrices = useSelector((state: RootState) =>
    printServiceProductPricesSelector.selectByIds(state, ids)
  ) as PrintServiceProductPriceEntity[]

  return { productPrices }
}
