import { PrintServiceProductEntity } from "@jackfruit/common"
import { useSelector } from "react-redux"
import { printServiceProductsSelector } from "~/redux/state/printServiceProducts"
import { RootState } from "~/redux/store"

export const usePrintServiceProducts = () => {
  const printServiceProducts = useSelector<
    RootState,
    PrintServiceProductEntity[]
  >(printServiceProductsSelector.selectAll)

  return { printServiceProducts }
}
