import { Heading, VStack } from "@chakra-ui/react"
import { BlockSubpageGridEntity, PageEntityHydrated } from "@jackfruit/common"
import React, { useCallback, useState } from "react"
import Masonry from "react-masonry-css"
import SubpageGridBlockCard from "../SubpageGridBlockCard"
import Pagination from "../Pagination"
import Wrapper from "../Wrapper"
import format from "date-fns/format"
import "./SubpageGridBlock.css"
import BlogPlaceholder from "~/images/Blog_Placeholder_v2_Final.svg"

const breakpointColumns = {
  default: 3,
  1000: 2,
  650: 1,
}
export interface Props {
  config: BlockSubpageGridEntity
  pages: PageEntityHydrated[]
}

const SubpageGridBlock: React.FC<Props> = ({ config, pages }) => {
  const { title, paginationLimit, displayTitles, displayPublishedAtDates } =
    config
  const totalItems = pages.length

  const sortedPages = pages.sort((thisPage, otherPage) => {
    const thisDate = new Date(thisPage.publishedAt ?? 0)
    const otherDate = new Date(otherPage.publishedAt ?? 0)

    return otherDate.getTime() - thisDate.getTime()
  })

  const [selectedPage, setSelectedPage] = useState(0)
  const [pagesToRender, setPagesToRender] = useState(
    sortedPages.slice(0, paginationLimit)
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setSelectedPage(page)
      setPagesToRender(
        sortedPages.slice(page * paginationLimit, (page + 1) * paginationLimit)
      )
    },
    [sortedPages, paginationLimit]
  )

  return (
    <Wrapper bg="gray.100" py={16}>
      <VStack position="relative">
        {title && (
          <Heading as="h2" mb={4}>
            {title}
          </Heading>
        )}
        <Masonry
          breakpointCols={breakpointColumns}
          className="subpage-grid"
          columnClassName="subpage-grid_column"
          style={{ width: "100%" }}
        >
          {pagesToRender.map(page => (
            <SubpageGridBlockCard
              key={page.id}
              featureImageUrl={
                page.pageSeo?.openGraphFeatureImage?.path ??
                `${BlogPlaceholder}`
              }
              link={page.path}
              title={page.name}
              subtitle={
                page.publishedAt
                  ? format(new Date(page.publishedAt), "d LLLL y")
                  : null
              }
              displayTitles={displayTitles}
              displayPublishedAtDates={displayPublishedAtDates}
              styles={{
                rounded: "lg",
                boxShadow: "base",
                _hover: { boxShadow: "md" },
              }}
            ></SubpageGridBlockCard>
          ))}
        </Masonry>
        <Pagination
          itemsPerPages={paginationLimit}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          selectedPage={selectedPage}
        />
      </VStack>
    </Wrapper>
  )
}

export default SubpageGridBlock
