import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  LinkBox,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { PrintServiceProductEntityHydrated } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProductImages } from "~/hooks/useProductImages"
import { useProductPrices } from "~/hooks/useProductPrices"
import { formatCurrency } from "~/services/Utils"

interface Props {
  product: PrintServiceProductEntityHydrated
  layout: "vertical" | "horizontal"
  index: number
  isCTAButtonEnabled: boolean
  ctaButtonText: string
}

const ProductGridItem: React.FC<Props> = ({
  product,
  layout,
  index,
  isCTAButtonEnabled,
  ctaButtonText,
}) => {
  const { id: productId, prices, shortDescription } = product
  const { t } = useTranslation()
  const { defaultCurrency } = useApplicationState()
  const { productImages } = useProductImages(productId)
  const { productPrices } = useProductPrices(prices)
  const process = useProcessActions()
  const productPrice = productPrices.find(
    price => price?.currency === defaultCurrency
  )
  const productImage =
    productImages.find(image => image?.metaData?.web?.thumbnail === true) ??
    productImages[0]
  const imageObjectFit =
    productImage?.metaData?.web?.grid?.imageFillType || "cover"

  const isLargerThan1000 = useMediaQuery("(min-width: 1000px)")
  //CTA button will only show up if the layout is
  //horizontal and the screen size is larger than 1000px
  const isShowCTAButton = Boolean(
    isCTAButtonEnabled && isLargerThan1000 && layout === "horizontal"
  )

  const handleSelectProduct = useCallback(() => {
    process.createLineItem({
      productId,
      createFromProduct: true,
    })
  }, [process, productId])

  return (
    <LinkBox
      role="group"
      bg="white"
      mx="auto"
      mb={4}
      rounded="base"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      onClick={handleSelectProduct}
      className={`product-grid-item product-grid-item-${layout} p-product-grid-item-${index}`}
      data-testid={product.id}
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: "scale(1.03)",
        cursor: "pointer",
        boxShadow: "lg",
      }}
    >
      <Box className="product-grid-image">
        <Image
          src={productImage?.thumbnail || "https://via.placeholder.com/150"}
          objectFit={imageObjectFit}
        />
      </Box>

      <Stack className="product-grid-name" justify="center" px={2}>
        <Box>
          <Flex h={12} align="center" pt={2}>
            <Heading as="h3" size="sm">
              {shortDescription}
            </Heading>
          </Flex>
          {productPrice && (
            <Text fontSize="sm" color="gray.400">
              {formatCurrency(productPrice.currency, productPrice.total)}
            </Text>
          )}
        </Box>
      </Stack>
      {isShowCTAButton && (
        <Button
          id={`cta-button--${productId}`}
          colorScheme="primary"
          color="primary.text"
          className="cta-button"
          size="sm"
          left="50%"
          bottom="55%"
          transform="translate(-50%,-50%)"
          display="none"
          _groupHover={{ display: "inline-block" }}
        >
          {ctaButtonText ||
            t(
              "components.components.product-grid.ProductGridItem.CTAButtonDefault"
            )}
        </Button>
      )}
    </LinkBox>
  )
}

export default ProductGridItem
