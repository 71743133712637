import { Box, Center, Heading } from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockEntityHydrated,
  PageEntityHydrated,
} from "@jackfruit/common"
import React from "react"
import { useSearchParam } from "react-use"
import CartBlockWrapper from "./blocks/CartBlockWrapper"
import CheckoutBlockWrapper from "./blocks/CheckoutBlockWrapper"
import FooterBlock from "./blocks/FooterBlock"
import FulfillmentBlockWrapper from "./blocks/FulfillmentBlockWrapper"
import HeaderBlock from "./blocks/HeaderBlock"
import HtmlBlock from "./blocks/HtmlBlock"
import ImageGridBlock from "./blocks/ImageGridBlock"
import ImageUploadBlock from "./blocks/ImageUploadBlock"
import OrderCompleteBlockWrapper from "./blocks/OrderCompleteBlockWrapper"
import OrderThankYouBlock from "./blocks/OrderThankYouBlock"
import PhotosUploadedBlockWrapper from "./blocks/PhotosUploadedBlockWrapper"
import ProductGridBlock from "./blocks/ProductGridBlock"
import RichTextBlock from "./blocks/RichTextBlock"
import SocialMediaBlock from "./blocks/SocialMediaBlock"
import StoreSearchBlockWrapper from "./blocks/StoreSearchBlockWrapper"
import SubpageGridBlock from "./blocks/SubpageGridBlock"
import TemplateBlockWrapper from "./blocks/TemplateBlockWrapper"
import ScrollElementWrapper from "./scroll-wrappers/ScrollElementWrapper"

interface Props {
  block: BlockEntityHydrated
  config: AppEntityHydrated
  page: PageEntityHydrated
}

const PageBlock: React.FC<Props> = ({ block, config, page }) => {
  let blockToReturn: any = null

  const hideHeader = useSearchParam("hideHeader") === "1"
  const hideFooter = useSearchParam("hideFooter") === "1"
  const focusMode = useSearchParam("focusMode") === "1"

  switch (block.type) {
    case "header":
      blockToReturn =
        hideHeader || focusMode ? (
          <></>
        ) : (
          <HeaderBlock
            config={block.header}
            pages={config.pages}
            appConfig={config}
          />
        )
      break
    case "rich-text":
      blockToReturn = <RichTextBlock config={block.richText} />
      break
    case "html":
      blockToReturn = <HtmlBlock config={block.html} appConfig={config} />
      break
    case "cart":
      blockToReturn = <CartBlockWrapper config={block.cart} />
      break
    case "image-upload":
      blockToReturn = <ImageUploadBlock config={block.imageUpload} />
      break
    case "fulfillment":
      blockToReturn = (
        <FulfillmentBlockWrapper config={block.fulfillment} page={page} />
      )
      break
    case "checkout":
      blockToReturn = (
        <CheckoutBlockWrapper config={block.checkout} pages={config.pages} />
      )
      break
    case "image-grid":
      blockToReturn = <ImageGridBlock config={block.imageGrid} />
      break
    case "store-search":
      blockToReturn = <StoreSearchBlockWrapper config={block.storeSearch} />
      break
    case "footer":
      blockToReturn =
        hideFooter || focusMode ? (
          <></>
        ) : (
          <FooterBlock config={block.footer} appConfig={config} />
        )
      break
    case "order-complete":
      blockToReturn = <OrderCompleteBlockWrapper config={block.orderComplete} />
      break
    case "photos-uploaded":
      blockToReturn = <PhotosUploadedBlockWrapper />
      break
    case "social-media":
      blockToReturn = (
        <SocialMediaBlock config={block.socialMedia} appConfig={config} />
      )
      break
    case "order-thank-you":
      blockToReturn = (
        <OrderThankYouBlock appConfig={config} config={block.orderThankYou} />
      )
      break
    case "template":
      blockToReturn = <TemplateBlockWrapper config={block.template} />
      break
    case "subpage-grid":
      blockToReturn = (
        <SubpageGridBlock config={block.subpageGrid} pages={page.subpages!} />
      )
      break
    case "product-grid":
      blockToReturn = <ProductGridBlock config={block.productGrid} />
      break
  }

  if (blockToReturn) {
    return (
      <Box
        className={[
          "block-container",
          `block-${block.type.toLowerCase()}`,
        ].join(" ")}
      >
        <ScrollElementWrapper id={block.elementId} name={block.type}>
          {blockToReturn}
        </ScrollElementWrapper>
      </Box>
    )
  }

  //Unknown block
  return (
    <Box>
      <Center>
        <Heading>Unknown Block: {block.type}</Heading>
      </Center>
    </Box>
  )
}

export default PageBlock
