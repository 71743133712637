import { Center, VStack } from "@chakra-ui/react"
import { BlockProductGridEntity } from "@jackfruit/common"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Masonry from "react-masonry-css"
import { useCart } from "~/hooks/useCart"
import { usePagination } from "~/hooks/usePagination"
import { useProducts } from "~/hooks/useProducts"
import BlockTitle from "../BlockTitle"
import CssLoader from "../CssLoader"
import Pagination from "../Pagination"
import Wrapper from "../Wrapper"
import ProductGridItem from "../product-grid/ProductGridItem"
import "./ProductGridBlock.scss"

export interface Props {
  config: BlockProductGridEntity
}

const ProductGridBlock: React.FC<Props> = ({ config }) => {
  const {
    itemsPerPage,
    layout,
    printServices,
    title,
    subtitle,
    isCTAButtonEnabled,
    ctaButtonText,
  } = config

  // Add productGrids to debugJson for test automation
  useEffect(() => {
    window.debugJson = window.debugJson || {}
    window.debugJson.productGrids = config
  }, [config])

  const { t } = useTranslation()
  const { cart } = useCart()
  const { fulfillment } = cart
  const printServiceConfig = printServices[fulfillment][0]
  const printServiceId = printServiceConfig?.id
  const productIds = printServiceId
    ? Object.keys(config.products[fulfillment][+printServiceId])
    : []
  const { products } = useProducts(productIds)
  const filteredProducts = products.filter(Boolean)
  const { page, currentPage, gotoPage } = usePagination(filteredProducts, {
    itemsPerPages: itemsPerPage,
  })

  // If no products are configured, show loading animation
  if (filteredProducts.length === 0) {
    return (
      <Center>
        <CssLoader label={t("components.blocks.TemplateBlockA.Loading")} />
      </Center>
    )
  }

  const breakpointColumns =
    layout === "horizontal"
      ? {
          default: 4,
          1360: 3,
          1000: 2,
          768: 1,
        }
      : 1

  return (
    <Wrapper id="p-product-grid" bg="gray.100" py={16}>
      <BlockTitle title={title} subtitle={subtitle} />
      <VStack position="relative">
        <Masonry
          breakpointCols={breakpointColumns}
          className="product-grid"
          columnClassName="product-grid_column"
          style={{ width: "100%" }}
        >
          {page.map((product, index) => (
            <ProductGridItem
              key={product.id}
              index={index}
              product={product}
              layout={layout}
              isCTAButtonEnabled={isCTAButtonEnabled}
              ctaButtonText={ctaButtonText}
            />
          ))}
        </Masonry>
        <Pagination
          itemsPerPages={itemsPerPage}
          totalItems={products.length}
          onPageChange={gotoPage}
          selectedPage={currentPage}
        />
      </VStack>
    </Wrapper>
  )
}

export default ProductGridBlock
