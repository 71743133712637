import { Box, Flex, Icon, Progress, Text } from "@chakra-ui/react"
import React from "react"
import { FiCheck, FiTruck } from "react-icons/fi"
import { ReactComponent as OrderThankYouBlockFrameSvg } from "~/images/OrderThankYouBlockFrame.svg"
import { useTranslation } from "react-i18next"

export interface Props {
  primaryColor: string
}

const DeliveryProgress: React.FC<Props> = ({ primaryColor }) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex direction="column">
        <Box
          width={{ base: 10, sm: 12, md: 16 }}
          height={{ base: 10, sm: 12, md: 16 }}
          mx={{ base: "auto", md: 0 }}
          borderRadius="full"
          bg={primaryColor ?? "primary.500"}
          boxShadow="md"
        >
          <Icon
            boxSize={{ base: 6, md: 10 }}
            as={FiCheck}
            color="white"
            mb={2}
            mt={{ base: 2, sm: 3 }}
          />
        </Box>
        <Text fontSize={{ base: "xs", md: "sm" }} fontWeight="bold" mt={3}>
          {t("components.thank-you.progress.DeliveryProgress.Received")}
        </Text>
      </Flex>

      <Box
        width={{ base: 16, sm: 24, md: 40 }}
        mt={{ base: 4, sm: 5, md: 7 }}
        mr={{ base: -1, sm: 0 }}
        ml={{ base: -1, sm: 0, md: 1 }}
      >
        <Progress
          size="sm"
          borderTopLeftRadius="base"
          borderBottomLeftRadius="base"
          bg="blackAlpha.100"
        />
      </Box>

      <Flex direction="column">
        <Box
          width={{ base: 10, sm: 12, md: 16 }}
          height={{ base: 10, sm: 12, md: 16 }}
          mx={{ base: "auto", sm: 0 }}
          borderRadius="full"
          boxShadow="0px 4.45px 4.45px 0px rgb(0 0 0 / 5%), inset 0px 4.45px 8.55px 0px rgb(0 0 0 / 10%);"
        >
          <Icon
            as={FiTruck}
            color="gray.400"
            boxSize={{ base: 5, md: 7 }}
            mb={2}
            mt={{ base: 3, sm: 3.5, md: 5 }}
          />
        </Box>
        <Text fontSize={{ base: "xs", md: "sm" }} mt={3}>
          {t("components.thank-you.progress.DeliveryProgress.Shipped")}
        </Text>
      </Flex>

      <Box
        width={{ base: 16, sm: 24, md: 40 }}
        mt={{ base: 4, sm: 5, md: 7 }}
        mr={{ base: -1, sm: 0, md: 1 }}
        ml={{ base: -1, sm: -0.5, md: 0 }}
      >
        <Progress
          size="sm"
          borderTopRightRadius="base"
          borderBottomRightRadius="base"
          bg="blackAlpha.100"
        />
      </Box>

      <Flex direction="column">
        <Box
          width={{ base: 10, sm: 12, md: 16 }}
          height={{ base: 10, sm: 12, md: 16 }}
          mx={{ base: "auto", md: "0" }}
          borderRadius="full"
          boxShadow="0px 4.45px 4.45px 0px rgb(0 0 0 / 5%), inset 0px 4.45px 8.55px 0px rgb(0 0 0 / 10%);"
        >
          <Icon
            boxSize={{ base: 6, md: 8 }}
            as={OrderThankYouBlockFrameSvg}
            mb={2}
            mt={{ base: 2, sm: 3, md: 4 }}
          />
        </Box>
        <Text fontSize={{ base: "xs", md: "sm" }} mt={3}>
          {t("components.thank-you.progress.DeliveryProgress.Delivered")}
        </Text>
      </Flex>
    </>
  )
}

export default DeliveryProgress
