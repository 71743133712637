import { Flex } from "@chakra-ui/react"
import React from "react"
import PickupProgress from "./progress/PickupProgress"
import DeliveryProgress from "./progress/DeliveryProgress"
export interface Props {
  fulfillment: "pickup" | "delivery"
  primaryColor: string
}

const OrderThankYouProgress: React.FC<Props> = ({
  fulfillment,
  primaryColor,
}) => {
  return (
    <Flex justify="center" mt={8} mb={4}>
      {fulfillment === "pickup" ? (
        <PickupProgress primaryColor={primaryColor} />
      ) : (
        <DeliveryProgress primaryColor={primaryColor} />
      )}
    </Flex>
  )
}

export default OrderThankYouProgress
