import { useSelector } from "react-redux"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { uploadsSelectors } from "~/redux/state/uploads"
import { RootState } from "~/redux/store"
import { usePageSession } from "./usePageSession"

export const useUploads = () => {
  const { pageSession } = usePageSession()
  const uploads = useSelector<RootState, UploadEntity[]>(state =>
    uploadsSelectors.selectByIds(state, pageSession.uploadIds)
  )

  return { uploads }
}
