import { Box, Flex } from "@chakra-ui/react"
import {
  AppEntityHydrated,
  BlockOrderThankYouEntityHydrated,
} from "@jackfruit/common"
import React from "react"
import { useApplicationState } from "~/hooks/useApplicationState"
import { useOrder } from "~/hooks/useOrder"
import { ReactComponent as OrderConfirmationSparklesLeftSvg } from "~/images/OrderConfirmationSparklesLeft.svg"
import { ReactComponent as OrderConfirmationSparklesRightSvg } from "~/images/OrderConfirmationSparklesRight.svg"
import OrderThankYouProgress from "../thank-you/OrderThankYouProgress"
import OrderThankYouText from "../thank-you/OrderThankYouText"
import Wrapper from "../Wrapper"

export interface Props {
  config: BlockOrderThankYouEntityHydrated
  appConfig: AppEntityHydrated
}

const OrderThankYouBlock: React.FC<Props> = ({ config, appConfig }) => {
  const { lastOrderId } = useApplicationState()
  const { primaryColor } = appConfig
  const { order: placedOrder } = useOrder(lastOrderId)

  if (!placedOrder) {
    return null
  }

  const { title, body } = config

  // nonce has already been used and generic thank you page should be shown
  const isFakeOrder = placedOrder.id === 1

  if (isFakeOrder) {
    return (
      <Wrapper py={8}>
        <Flex justify="space-between" align="center">
          <Box display={{ base: "none", md: "block" }} w={24}>
            <OrderConfirmationSparklesLeftSvg width={100} />
          </Box>

          <Box textAlign="center">
            <OrderThankYouText
              appConfig={appConfig}
              placedOrder={placedOrder}
              title={title}
              body={" "}
            />
          </Box>

          <Box display={{ base: "none", md: "block" }} w={24}>
            <OrderConfirmationSparklesRightSvg width="100" />
          </Box>
        </Flex>
      </Wrapper>
    )
  }

  return (
    <Wrapper py={8}>
      <Flex justify="space-between" align="center">
        <Box display={{ base: "none", md: "block" }} w={24}>
          <OrderConfirmationSparklesLeftSvg width={100} />
        </Box>

        <Box textAlign="center">
          <OrderThankYouText
            appConfig={appConfig}
            placedOrder={placedOrder}
            title={title}
            body={body}
          />

          <OrderThankYouProgress
            fulfillment={placedOrder.cart?.fulfillment}
            primaryColor={primaryColor}
          />
        </Box>

        <Box display={{ base: "none", md: "block" }} w={24}>
          <OrderConfirmationSparklesRightSvg width="100" />
        </Box>
      </Flex>
    </Wrapper>
  )
}

export default OrderThankYouBlock
